import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'close-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CloseIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '16';

  render() {
    return (
      <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
          <path
            d="M5.73 4.574a.75.75 0 1 0-1.061 1.06l4.419 4.42-4.42 4.42a.75.75 0 1 0 1.061 1.06l4.42-4.42 4.419 4.42a.75.75 0 0 0 1.06-1.06l-4.419-4.42 4.42-4.42a.75.75 0 1 0-1.061-1.06l-4.42 4.42-4.419-4.42Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" transform="translate(.148 .054)" d="M0 0h20v20H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
