import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'edit-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class EditIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '16';

  render() {
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.245 0.817394C12.3318 -0.269413 14.0938 -0.269413 15.1806 0.817394C16.2222 1.85892 16.2656 3.5206 15.3108 4.6138L15.1806 4.75307L5.57482 14.3589C5.36973 14.564 5.12299 14.7215 4.85212 14.8213L4.68684 14.8742L0.631867 15.9801C0.285278 16.0746 -0.0347856 15.7861 0.00276241 15.4455L0.0179263 15.3662L1.12383 11.3112C1.20014 11.0314 1.33621 10.7722 1.52188 10.5511L1.63914 10.4232L11.245 0.817394ZM10.3848 3.09229L2.34625 11.1303C2.25396 11.2226 2.18093 11.3318 2.13086 11.4514L2.08859 11.5743L1.21196 14.785L4.42372 13.9094C4.50767 13.8865 4.5879 13.8529 4.66253 13.8096L4.77003 13.7375L4.86771 13.6518L12.9058 5.61329L10.3848 3.09229ZM14.4735 1.5245C13.8159 0.8669 12.7725 0.830367 12.072 1.4149L11.9521 1.5245L11.0918 2.38529L13.6128 4.90629L14.4735 4.04596C15.1311 3.38836 15.1677 2.34488 14.5831 1.64441L14.4735 1.5245Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
