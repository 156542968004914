import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-check-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarCheckIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M14.75 0C16.5449 0 18 1.45507 18 3.25V9.02182C17.5368 8.72526 17.0335 8.48584 16.5 8.3135V5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H8.3135C8.48584 17.0335 8.72526 17.5368 9.02182 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5ZM20 14.5C20 17.5376 17.5376 20 14.5 20C11.4624 20 9 17.5376 9 14.5C9 11.4624 11.4624 9 14.5 9C17.5376 9 20 11.4624 20 14.5ZM17.8536 12.1464C17.6583 11.9512 17.3417 11.9512 17.1464 12.1464L13.5 15.7929L11.8536 14.1464C11.6583 13.9512 11.3417 13.9512 11.1464 14.1464C10.9512 14.3417 10.9512 14.6583 11.1464 14.8536L13.1464 16.8536C13.3417 17.0488 13.6583 17.0488 13.8536 16.8536L17.8536 12.8536C18.0488 12.6583 18.0488 12.3417 17.8536 12.1464Z"
        />
      </svg>
    );
  }
}
