import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'add-circle-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class AddCircleIcon {
  @Prop() width: string = '24';
  @Prop() height: string = '25';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2.5c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10ZM12 4a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm0 3.5a.75.75 0 0 1 .75.75v3.5h3.5a.75.75 0 0 1 0 1.5h-3.5v3.5a.75.75 0 0 1-1.5 0v-3.5h-3.5a.75.75 0 0 1 0-1.5h3.5v-3.5A.75.75 0 0 1 12 7.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
