import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'calendar-cancel-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CalendarCancelIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.5 9a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm-2.476 3.024a.5.5 0 0 0 0 .707l1.769 1.77-1.767 1.766a.5.5 0 1 0 .707.708l1.767-1.767 1.77 1.769a.5.5 0 1 0 .707-.707L15.208 14.5l1.771-1.77a.5.5 0 0 0-.707-.707l-1.771 1.77-1.77-1.77a.5.5 0 0 0-.707 0ZM14.75 0A3.25 3.25 0 0 1 18 3.25l.001 5.773a6.47 6.47 0 0 0-1.5-.71L16.5 5.5h-15v9.25c0 .966.784 1.75 1.75 1.75h5.064a6.47 6.47 0 0 0 .709 1.501L3.25 18A3.25 3.25 0 0 1 0 14.75V3.25A3.25 3.25 0 0 1 3.25 0h11.5Zm0 1.5H3.25A1.75 1.75 0 0 0 1.5 3.25V4h15v-.75a1.75 1.75 0 0 0-1.75-1.75Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
