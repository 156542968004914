import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'delete-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class DeleteIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '17';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.5 2.75C9.5 1.92157 8.82843 1.25 8 1.25C7.17157 1.25 6.5 1.92157 6.5 2.75H5.5C5.5 1.36929 6.61929 0.25 8 0.25C9.38071 0.25 10.5 1.36929 10.5 2.75H15C15.2761 2.75 15.5 2.97386 15.5 3.25C15.5 3.52614 15.2761 3.75 15 3.75H14.446L13.1499 14.9792C13.0335 15.9884 12.179 16.75 11.1631 16.75H4.83688C3.821 16.75 2.9665 15.9884 2.85006 14.9792L1.553 3.75H1C0.75454 3.75 0.550392 3.57312 0.508056 3.33988L0.5 3.25C0.5 2.97386 0.723858 2.75 1 2.75H9.5ZM13.438 3.75H2.561L3.84347 14.8646C3.90169 15.3692 4.32894 15.75 4.83688 15.75H11.1631C11.6711 15.75 12.0983 15.3692 12.1565 14.8646L13.438 3.75ZM6.5 6.25C6.74546 6.25 6.94961 6.40477 6.99194 6.60886L7 6.6875V12.8125C7 13.0541 6.77614 13.25 6.5 13.25C6.25454 13.25 6.05039 13.0952 6.00806 12.8911L6 12.8125V6.6875C6 6.44588 6.22386 6.25 6.5 6.25ZM9.5 6.25C9.74546 6.25 9.94961 6.40477 9.99194 6.60886L10 6.6875V12.8125C10 13.0541 9.77614 13.25 9.5 13.25C9.25454 13.25 9.05039 13.0952 9.00806 12.8911L9 12.8125V6.6875C9 6.44588 9.22386 6.25 9.5 6.25Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
