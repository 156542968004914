@import '../../../common/styles/variables.scss';

.checkbox-container {
  @include default-css-variables;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p.error {
    color: var(--nylas-error);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--nylas-font-family);
    margin: 0;
  }
}

label {
  display: flex;
  align-items: center;
  font-family: var(--nylas-font-family);
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:focus ~ .checkmark,
    &:hover ~ .checkmark {
      border-color: var(--nylas-primary);
    }

    &:checked ~ .checkmark {
      color: var(--nylas-base-0);
      background: var(--nylas-primary)
        url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
        50% 40% no-repeat;
      border: 2px solid var(--nylas-primary);
    }
  }

  .checkmark {
    border-radius: var(--nylas-border-radius);
    border: 2px solid var(--nylas-base-800);
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .label {
    margin-left: 32px;
    font-size: 16px;
    font-weight: 400;
    color: var(--nylas-base-800);
    line-height: 24px;
  }
}
