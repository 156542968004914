import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'bold-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class BoldIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    // Bold icon svg
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 4V20M9.5 4H15.5C17.7091 4 19.5 5.79086 19.5 8C19.5 10.2091 17.7091 12 15.5 12H9.5H16.5C18.7091 12 20.5 13.7909 20.5 16C20.5 18.2091 18.7091 20 16.5 20H9.5M9.5 4V20M9.5 4H4M9.5 20H4"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}
