import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'clock-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ClockIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '16';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5ZM8 1.4375C4.37563 1.4375 1.4375 4.37563 1.4375 8C1.4375 11.6244 4.37563 14.5625 8 14.5625C11.6244 14.5625 14.5625 11.6244 14.5625 8C14.5625 4.37563 11.6244 1.4375 8 1.4375ZM7.53125 3.3125C7.76137 3.3125 7.95276 3.47832 7.99245 3.69699L8 3.78125V8H10.3438C10.6026 8 10.8125 8.20987 10.8125 8.46875C10.8125 8.69887 10.6467 8.89026 10.428 8.92995L10.3438 8.9375H7.53125C7.30113 8.9375 7.10974 8.77168 7.07005 8.55301L7.0625 8.46875V3.78125C7.0625 3.52237 7.27237 3.3125 7.53125 3.3125Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
