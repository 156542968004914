import { Component, h, Prop, State, Event, EventEmitter, Listen, Element } from '@stencil/core';

/**
 * The `checkbox-component` component is a UI component that allows users to select a checkbox.
 * This component is used in the scheduling form to input checkbox type inputs.
 */
@Component({
  tag: 'checkbox-component',
  styleUrl: 'checkbox-component.scss',
  shadow: true,
})
export class CheckboxComponent {
  @Element() el!: HTMLElement;
  private readonly componentType: string = 'checkbox';

  /**
   * The name of the checkbox. This is used to identify the checkbox when submitting a form.
   */
  @Prop() name: string = 'checkbox';
  /**
   * The default value of the checkbox. This is the value that is displayed when the checkbox is rendered.
   */
  @Prop() checked?: boolean;
  /**
   * The label of the checkbox. This is displayed next to the checkbox.
   */
  @Prop() label: string = '';
  /**
   * Whether the checkbox is required. If true, the checkbox must be checked when submitting a form.
   * Default is false. If the checkbox is required and not checked, an error message is displayed.
   */
  @Prop() required: boolean = false;
  /**
   * The checked state of the checkbox.
   */
  @State() isChecked!: boolean;
  /**
   * The error message state.
   */
  @State() error: string = '';

  /**
   * This event is fired when the checkbox is toggled.
   */
  @Event() nylasFormCheckboxToggled!: EventEmitter<{
    checked: boolean;
    name: string;
    label: string;
  }>;

  // Lifecycle events
  componentWillLoad() {
    this.el.setAttribute('component-type', this.componentType);
  }

  componentDidLoad() {
    this.isChecked = this.checked || false;
  }

  // Event listeners
  /**
   * Listen for the bookingFormSubmitted event to validate the input value when the form is submitted.
   */
  @Listen('bookingFormSubmitted', { target: 'document' })
  handleBookingFormSubmitted(event: CustomEvent) {
    if (this.required && !this.isChecked) {
      this.error = `${this.label} is required.`;
      return;
    }
    if (this.error) {
      event.preventDefault();
    }
  }

  // Methods
  toggleCheck() {
    this.error = '';
    this.isChecked = !this.isChecked;
    this.nylasFormCheckboxToggled.emit({
      checked: this.isChecked,
      name: this.name,
      label: this.label,
    });
  }

  render() {
    return (
      <div class="checkbox-container">
        <label>
          <input type="checkbox" name={this.name} checked={this.isChecked} onChange={() => this.toggleCheck()} />
          <span class="checkmark"></span>
          <slot name="label">
            <span class="label">{this.label}</span>
          </slot>
        </label>
        {this.error && <p class="error">{this.error}</p>}
      </div>
    );
  }
}
