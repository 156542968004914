import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'checkmark-circle-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CheckmarkCircleIcon {
  @Prop() width: string = '20';
  @Prop() height: string = '20';

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.width} height={this.height} fill="none" viewBox="0 0 20 20">
        <path
          fill="currentColor"
          d="M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm0 1a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm3.358 4.646a.5.5 0 0 1 .058.638l-.058.07-4.004 4.004a.5.5 0 0 1-.638.058l-.07-.058-2-2a.5.5 0 0 1 .638-.765l.07.058L9 11.298l3.651-3.652a.5.5 0 0 1 .707 0Z"
        />
      </svg>
    );
  }
}
