import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'person-clipboard-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class PersonClipboardIcon {
  @Prop() width: string = '12';
  @Prop() height: string = '17';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 7.5C4 6.39543 4.89543 5.5 6 5.5C7.10457 5.5 8 6.39543 8 7.5C8 8.60457 7.10457 9.5 6 9.5C4.89543 9.5 4 8.60457 4 7.5ZM6 6.5C5.44772 6.5 5 6.94772 5 7.5C5 8.05228 5.44772 8.5 6 8.5C6.55228 8.5 7 8.05228 7 7.5C7 6.94772 6.55228 6.5 6 6.5ZM3.72077 10.5C3.10838 10.5 2.40961 10.9146 2.38992 11.6973C2.37693 12.2135 2.48426 12.9381 3.04816 13.5319C3.61451 14.1283 4.55301 14.5 6.00001 14.5C7.44702 14.5 8.38552 14.1283 8.95187 13.5319C9.51577 12.9381 9.6231 12.2135 9.61011 11.6973C9.59042 10.9146 8.89165 10.5 8.27925 10.5H3.72077ZM3.3896 11.7225C3.39099 11.6675 3.41262 11.6226 3.46275 11.582C3.51876 11.5366 3.6101 11.5 3.72077 11.5H8.27925C8.38993 11.5 8.48127 11.5366 8.53728 11.582C8.58741 11.6226 8.60904 11.6675 8.61042 11.7225C8.61975 12.0933 8.53969 12.5138 8.22676 12.8433C7.91628 13.1702 7.28849 13.5 6.00001 13.5C4.71154 13.5 4.08375 13.1702 3.77327 12.8433C3.46033 12.5138 3.38027 12.0933 3.3896 11.7225ZM5 0.5C3.89543 0.5 3 1.39543 3 2.5H1.5C0.671573 2.5 0 3.17157 0 4V15C0 15.8284 0.671572 16.5 1.5 16.5H10.5C11.3284 16.5 12 15.8284 12 15V4C12 3.17157 11.3284 2.5 10.5 2.5H9C9 1.39543 8.10457 0.5 7 0.5H5ZM8.73244 3.5H10.5C10.7761 3.5 11 3.72386 11 4V15C11 15.2761 10.7761 15.5 10.5 15.5H1.5C1.22386 15.5 1 15.2761 1 15V4C1 3.72386 1.22386 3.5 1.5 3.5H3.26756C3.61337 4.0978 4.25972 4.5 5 4.5H7C7.74028 4.5 8.38663 4.0978 8.73244 3.5ZM4 2.5C4 1.94772 4.44772 1.5 5 1.5H7C7.55228 1.5 8 1.94772 8 2.5C8 3.05228 7.55228 3.5 7 3.5H5C4.44772 3.5 4 3.05228 4 2.5Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
