import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'play-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class PlayIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      // Play icon svg
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="auto" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 12L3 21V3L21 12Z" stroke="auto" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    );
  }
}
