import { OpenHours } from '@nylas/core';

export type Locale = {
  noDateSelected: string;
  rescheduleTitle: string;
  days: {
    sunday: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
  };
  time: {
    hour: string;
    hours: string;
    minute: string;
    minutes: string;
  };
  months: {
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    june: string;
    july: string;
    august: string;
    september: string;
    october: string;
    november: string;
    december: string;
  };
  nextButton: string;
  selectedDayAndYear: string;
  selectedTime: string;
  name: string;
  namePlaceholder: string;
  email: string;
  emailPlaceholder: string;
  guestEmail: string;
  guestEmailPlaceholder: string;
  addGuest: string;
  addAnotherGuest: string;
  backButton: string;
  bookNowButton: string;
  bookingConfirmed: string;
  bookingConfirmedDescription: string;
  bookingSent: string;
  bookingSentDescription: string;
  bookingRescheduled: string;
  bookingDateAndTimeHeader: string;
  cancelBookingButton: string;
  rescheduleBookingButton: string;
  rejectBookingButton: string;
  confirmBookingButton: string;
  bookingPendingTitle: string;
  bookingPendingDescription: string;
  confirmedEventCardTitle: string;
  confirmedEventCardDescription: string;
  cancelBookingTitle: string;
  cancelBookingMessage: string;
  reasonForCancellation: string;
  goBackButton: string;
  bookingCancelledTitle: string;
  bookingCancelledMessage: string;
  closeButton: string;
  closingButton: string;
  fieldRequired: string;
  invalidInputFormat: string;
  createBookingErrorTitle: string;
  getAvailabilityErrorTitle: string;
  cancelBookingErrorTitle: string;
  rescheduleBookingErrorTitle: string;
  getUISettingErrorTitle: string;
  rejectBookingErrorTitle: string;
  confirmBookingErrorTitle: string;
  genericErrorTitle: string;
  schedulingComponentErrorTitle: string;
  sessionIdRequiredErrorMessage: string;
  publicConfigErrorMessage: string;
  invalidTimeslotErrorTitle: string;
  invalidTimeslotErrorMessage: string;
  cancellationErrorTitle: string;
  cancellationErrorMessage: string;
  minimumCancellationErrorMessage: string;
  cancelBokingFormError: string;
};

export enum LANGUAGE_CODE {
  en = 'en',
  es = 'es',
  fr = 'fr',
  de = 'de',
  sv = 'sv',
  zh = 'zh',
  ja = 'ja',
  nl = 'nl',
}

export const LANGUAGE_MAP: {
  [key: Navigator['language']]: string;
} = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsche',
  sv: 'Svenska',
  zh: '中文',
  ja: '日本語',
  nl: 'Nederlands',
};

export const LANGUAGE_CODE_MAP: {
  [key: string]: string;
} = {
  en: 'en',
  es: 'es',
  fr: 'fr',
  de: 'de',
  sv: 'sv',
  zh: 'zh',
  ja: 'ja',
  nl: 'nl',
};

export const TIMEZONE_MAP = {
  'Africa/Abidjan': 'Africa / Abidjan',
  'Africa/Accra': 'Africa / Accra',
  'Africa/Addis_Ababa': 'Africa / Addis Ababa',
  'Africa/Algiers': 'Africa / Algiers',
  'Africa/Asmara': 'Africa / Asmara',
  'Africa/Asmera': 'Africa / Asmera',
  'Africa/Bamako': 'Africa / Bamako',
  'Africa/Bangui': 'Africa / Bangui',
  'Africa/Banjul': 'Africa / Banjul',
  'Africa/Bissau': 'Africa / Bissau',
  'Africa/Blantyre': 'Africa / Blantyre',
  'Africa/Brazzaville': 'Africa / Brazzaville',
  'Africa/Bujumbura': 'Africa / Bujumbura',
  'Africa/Cairo': 'Africa / Cairo',
  'Africa/Casablanca': 'Africa / Casablanca',
  'Africa/Ceuta': 'Africa / Ceuta',
  'Africa/Conakry': 'Africa / Conakry',
  'Africa/Dakar': 'Africa / Dakar',
  'Africa/Dar_es_Salaam': 'Africa / Dar es Salaam',
  'Africa/Djibouti': 'Africa / Djibouti',
  'Africa/Douala': 'Africa / Douala',
  'Africa/El_Aaiun': 'Africa / El Aaiun',
  'Africa/Freetown': 'Africa / Freetown',
  'Africa/Gaborone': 'Africa / Gaborone',
  'Africa/Harare': 'Africa / Harare',
  'Africa/Johannesburg': 'Africa / Johannesburg',
  'Africa/Juba': 'Africa / Juba',
  'Africa/Kampala': 'Africa / Kampala',
  'Africa/Khartoum': 'Africa / Khartoum',
  'Africa/Kigali': 'Africa / Kigali',
  'Africa/Kinshasa': 'Africa / Kinshasa',
  'Africa/Lagos': 'Africa / Lagos',
  'Africa/Libreville': 'Africa / Libreville',
  'Africa/Lome': 'Africa / Lome',
  'Africa/Luanda': 'Africa / Luanda',
  'Africa/Lubumbashi': 'Africa / Lubumbashi',
  'Africa/Lusaka': 'Africa / Lusaka',
  'Africa/Malabo': 'Africa / Malabo',
  'Africa/Maputo': 'Africa / Maputo',
  'Africa/Maseru': 'Africa / Maseru',
  'Africa/Mbabane': 'Africa / Mbabane',
  'Africa/Mogadishu': 'Africa / Mogadishu',
  'Africa/Monrovia': 'Africa / Monrovia',
  'Africa/Nairobi': 'Africa / Nairobi',
  'Africa/Ndjamena': 'Africa / Ndjamena',
  'Africa/Niamey': 'Africa / Niamey',
  'Africa/Nouakchott': 'Africa / Nouakchott',
  'Africa/Ouagadougou': 'Africa / Ouagadougou',
  'Africa/Porto-Novo': 'Africa / Porto-Novo',
  'Africa/Sao_Tome': 'Africa / Sao Tome',
  'Africa/Timbuktu': 'Africa / Timbuktu',
  'Africa/Tripoli': 'Africa / Tripoli',
  'Africa/Tunis': 'Africa / Tunis',
  'Africa/Windhoek': 'Africa / Windhoek',
  'America/Adak': 'America / Adak',
  'America/Anchorage': 'America / Anchorage',
  'America/Anguilla': 'America / Anguilla',
  'America/Antigua': 'America / Antigua',
  'America/Araguaina': 'America / Araguaina',
  'America/Argentina/Buenos_Aires': 'America / Argentina / Buenos Aires',
  'America/Argentina/Catamarca': 'America / Argentina / Catamarca',
  'America/Argentina/ComodRivadavia': 'America / Argentina / ComodRivadavia',
  'America/Argentina/Cordoba': 'America / Argentina / Cordoba',
  'America/Argentina/Jujuy': 'America / Argentina / Jujuy',
  'America/Argentina/La_Rioja': 'America / Argentina / La Rioja',
  'America/Argentina/Mendoza': 'America / Argentina / Mendoza',
  'America/Argentina/Rio_Gallegos': 'America / Argentina / Rio Gallegos',
  'America/Argentina/Salta': 'America / Argentina / Salta',
  'America/Argentina/San_Juan': 'America / Argentina / San Juan',
  'America/Argentina/San_Luis': 'America / Argentina / San Luis',
  'America/Argentina/Tucuman': 'America / Argentina / Tucuman',
  'America/Argentina/Ushuaia': 'America / Argentina / Ushuaia',
  'America/Aruba': 'America / Aruba',
  'America/Asuncion': 'America / Asuncion',
  'America/Atikokan': 'America / Atikokan',
  'America/Atka': 'America / Atka',
  'America/Bahia': 'America / Bahia',
  'America/Bahia_Banderas': 'America / Bahia Banderas',
  'America/Barbados': 'America / Barbados',
  'America/Belem': 'America / Belem',
  'America/Belize': 'America / Belize',
  'America/Blanc-Sablon': 'America / Blanc-Sablon',
  'America/Boa_Vista': 'America / Boa Vista',
  'America/Bogota': 'America / Bogota',
  'America/Boise': 'America / Boise',
  'America/Buenos_Aires': 'America / Buenos Aires',
  'America/Cambridge_Bay': 'America / Cambridge Bay',
  'America/Campo_Grande': 'America / Campo Grande',
  'America/Cancun': 'America / Cancun',
  'America/Caracas': 'America / Caracas',
  'America/Catamarca': 'America / Catamarca',
  'America/Cayenne': 'America / Cayenne',
  'America/Cayman': 'America / Cayman',
  'America/Chicago': 'America / Chicago',
  'America/Chihuahua': 'America / Chihuahua',
  'America/Ciudad_Juarez': 'America / Ciudad Juarez',
  'America/Coral_Harbour': 'America / Coral Harbour',
  'America/Cordoba': 'America / Cordoba',
  'America/Costa_Rica': 'America / Costa Rica',
  'America/Creston': 'America / Creston',
  'America/Cuiaba': 'America / Cuiaba',
  'America/Curacao': 'America / Curacao',
  'America/Danmarkshavn': 'America / Danmarkshavn',
  'America/Dawson': 'America / Dawson',
  'America/Dawson_Creek': 'America / Dawson Creek',
  'America/Denver': 'America / Denver',
  'America/Detroit': 'America / Detroit',
  'America/Dominica': 'America / Dominica',
  'America/Edmonton': 'America / Edmonton',
  'America/Eirunepe': 'America / Eirunepe',
  'America/El_Salvador': 'America / El Salvador',
  'America/Ensenada': 'America / Ensenada',
  'America/Fort_Nelson': 'America / Fort Nelson',
  'America/Fort_Wayne': 'America / Fort Wayne',
  'America/Fortaleza': 'America / Fortaleza',
  'America/Glace_Bay': 'America / Glace Bay',
  'America/Godthab': 'America / Godthab',
  'America/Goose_Bay': 'America / Goose Bay',
  'America/Grand_Turk': 'America / Grand Turk',
  'America/Grenada': 'America / Grenada',
  'America/Guadeloupe': 'America / Guadeloupe',
  'America/Guatemala': 'America / Guatemala',
  'America/Guayaquil': 'America / Guayaquil',
  'America/Guyana': 'America / Guyana',
  'America/Halifax': 'America / Halifax',
  'America/Havana': 'America / Havana',
  'America/Hermosillo': 'America / Hermosillo',
  'America/Indiana/Indianapolis': 'America / Indiana / Indianapolis',
  'America/Indiana/Knox': 'America / Indiana / Knox',
  'America/Indiana/Marengo': 'America / Indiana / Marengo',
  'America/Indiana/Petersburg': 'America / Indiana / Petersburg',
  'America/Indiana/Tell_City': 'America / Indiana / Tell City',
  'America/Indiana/Vevay': 'America / Indiana / Vevay',
  'America/Indiana/Vincennes': 'America / Indiana / Vincennes',
  'America/Indiana/Winamac': 'America / Indiana / Winamac',
  'America/Indianapolis': 'America / Indianapolis',
  'America/Inuvik': 'America / Inuvik',
  'America/Iqaluit': 'America / Iqaluit',
  'America/Jamaica': 'America / Jamaica',
  'America/Jujuy': 'America / Jujuy',
  'America/Juneau': 'America / Juneau',
  'America/Kentucky/Louisville': 'America / Kentucky / Louisville',
  'America/Kentucky/Monticello': 'America / Kentucky / Monticello',
  'America/Knox_IN': 'America / Knox IN',
  'America/Kralendijk': 'America / Kralendijk',
  'America/La_Paz': 'America / La Paz',
  'America/Lima': 'America / Lima',
  'America/Los_Angeles': 'America / Los Angeles',
  'America/Louisville': 'America / Louisville',
  'America/Lower_Princes': 'America / Lower Princes',
  'America/Maceio': 'America / Maceio',
  'America/Managua': 'America / Managua',
  'America/Manaus': 'America / Manaus',
  'America/Marigot': 'America / Marigot',
  'America/Martinique': 'America / Martinique',
  'America/Matamoros': 'America / Matamoros',
  'America/Mazatlan': 'America / Mazatlan',
  'America/Mendoza': 'America / Mendoza',
  'America/Menominee': 'America / Menominee',
  'America/Merida': 'America / Merida',
  'America/Metlakatla': 'America / Metlakatla',
  'America/Mexico_City': 'America / Mexico City',
  'America/Miquelon': 'America / Miquelon',
  'America/Moncton': 'America / Moncton',
  'America/Monterrey': 'America / Monterrey',
  'America/Montevideo': 'America / Montevideo',
  'America/Montreal': 'America / Montreal',
  'America/Montserrat': 'America / Montserrat',
  'America/Nassau': 'America / Nassau',
  'America/New_York': 'America / New York',
  'America/Nipigon': 'America / Nipigon',
  'America/Nome': 'America / Nome',
  'America/Noronha': 'America / Noronha',
  'America/North_Dakota/Beulah': 'America / North Dakota / Beulah',
  'America/North_Dakota/Center': 'America / North Dakota / Center',
  'America/North_Dakota/New_Salem': 'America / North Dakota / New Salem',
  'America/Nuuk': 'America / Nuuk',
  'America/Ojinaga': 'America / Ojinaga',
  'America/Panama': 'America / Panama',
  'America/Pangnirtung': 'America / Pangnirtung',
  'America/Paramaribo': 'America / Paramaribo',
  'America/Phoenix': 'America / Phoenix',
  'America/Port-au-Prince': 'America / Port-au-Prince',
  'America/Port_of_Spain': 'America / Port of Spain',
  'America/Porto_Acre': 'America / Porto Acre',
  'America/Porto_Velho': 'America / Porto Velho',
  'America/Puerto_Rico': 'America / Puerto Rico',
  'America/Punta_Arenas': 'America / Punta Arenas',
  'America/Rainy_River': 'America / Rainy River',
  'America/Rankin_Inlet': 'America / Rankin Inlet',
  'America/Recife': 'America / Recife',
  'America/Regina': 'America / Regina',
  'America/Resolute': 'America / Resolute',
  'America/Rio_Branco': 'America / Rio Branco',
  'America/Rosario': 'America / Rosario',
  'America/Santa_Isabel': 'America / Santa Isabel',
  'America/Santarem': 'America / Santarem',
  'America/Santiago': 'America / Santiago',
  'America/Santo_Domingo': 'America / Santo Domingo',
  'America/Sao_Paulo': 'America / Sao Paulo',
  'America/Scoresbysund': 'America / Scoresbysund',
  'America/Shiprock': 'America / Shiprock',
  'America/Sitka': 'America / Sitka',
  'America/St_Barthelemy': 'America / St Barthelemy',
  'America/St_Johns': 'America / St Johns',
  'America/St_Kitts': 'America / St Kitts',
  'America/St_Lucia': 'America / St Lucia',
  'America/St_Thomas': 'America / St Thomas',
  'America/St_Vincent': 'America / St Vincent',
  'America/Swift_Current': 'America / Swift Current',
  'America/Tegucigalpa': 'America / Tegucigalpa',
  'America/Thule': 'America / Thule',
  'America/Thunder_Bay': 'America / Thunder Bay',
  'America/Tijuana': 'America / Tijuana',
  'America/Toronto': 'America / Toronto',
  'America/Tortola': 'America / Tortola',
  'America/Vancouver': 'America / Vancouver',
  'America/Virgin': 'America / Virgin',
  'America/Whitehorse': 'America / Whitehorse',
  'America/Winnipeg': 'America / Winnipeg',
  'America/Yakutat': 'America / Yakutat',
  'America/Yellowknife': 'America / Yellowknife',
  'Antarctica/Casey': 'Antarctica / Casey',
  'Antarctica/Davis': 'Antarctica / Davis',
  'Antarctica/DumontDUrville': 'Antarctica / DumontDUrville',
  'Antarctica/Macquarie': 'Antarctica / Macquarie',
  'Antarctica/Mawson': 'Antarctica / Mawson',
  'Antarctica/McMurdo': 'Antarctica / McMurdo',
  'Antarctica/Palmer': 'Antarctica / Palmer',
  'Antarctica/Rothera': 'Antarctica / Rothera',
  'Antarctica/South_Pole': 'Antarctica / South Pole',
  'Antarctica/Syowa': 'Antarctica / Syowa',
  'Antarctica/Troll': 'Antarctica / Troll',
  'Antarctica/Vostok': 'Antarctica / Vostok',
  'Arctic/Longyearbyen': 'Arctic / Longyearbyen',
  'Asia/Aden': 'Asia / Aden',
  'Asia/Almaty': 'Asia / Almaty',
  'Asia/Amman': 'Asia / Amman',
  'Asia/Anadyr': 'Asia / Anadyr',
  'Asia/Aqtau': 'Asia / Aqtau',
  'Asia/Aqtobe': 'Asia / Aqtobe',
  'Asia/Ashgabat': 'Asia / Ashgabat',
  'Asia/Ashkhabad': 'Asia / Ashkhabad',
  'Asia/Atyrau': 'Asia / Atyrau',
  'Asia/Baghdad': 'Asia / Baghdad',
  'Asia/Bahrain': 'Asia / Bahrain',
  'Asia/Baku': 'Asia / Baku',
  'Asia/Bangkok': 'Asia / Bangkok',
  'Asia/Barnaul': 'Asia / Barnaul',
  'Asia/Beirut': 'Asia / Beirut',
  'Asia/Bishkek': 'Asia / Bishkek',
  'Asia/Brunei': 'Asia / Brunei',
  'Asia/Calcutta': 'Asia / Calcutta',
  'Asia/Chita': 'Asia / Chita',
  'Asia/Choibalsan': 'Asia / Choibalsan',
  'Asia/Chongqing': 'Asia / Chongqing',
  'Asia/Chungking': 'Asia / Chungking',
  'Asia/Colombo': 'Asia / Colombo',
  'Asia/Dacca': 'Asia / Dacca',
  'Asia/Damascus': 'Asia / Damascus',
  'Asia/Dhaka': 'Asia / Dhaka',
  'Asia/Dili': 'Asia / Dili',
  'Asia/Dubai': 'Asia / Dubai',
  'Asia/Dushanbe': 'Asia / Dushanbe',
  'Asia/Famagusta': 'Asia / Famagusta',
  'Asia/Gaza': 'Asia / Gaza',
  'Asia/Harbin': 'Asia / Harbin',
  'Asia/Hebron': 'Asia / Hebron',
  'Asia/Ho_Chi_Minh': 'Asia / Ho Chi Minh',
  'Asia/Hong_Kong': 'Asia / Hong Kong',
  'Asia/Hovd': 'Asia / Hovd',
  'Asia/Irkutsk': 'Asia / Irkutsk',
  'Asia/Istanbul': 'Asia / Istanbul',
  'Asia/Jakarta': 'Asia / Jakarta',
  'Asia/Jayapura': 'Asia / Jayapura',
  'Asia/Jerusalem': 'Asia / Jerusalem',
  'Asia/Kabul': 'Asia / Kabul',
  'Asia/Kamchatka': 'Asia / Kamchatka',
  'Asia/Karachi': 'Asia / Karachi',
  'Asia/Kashgar': 'Asia / Kashgar',
  'Asia/Kathmandu': 'Asia / Kathmandu',
  'Asia/Katmandu': 'Asia / Katmandu',
  'Asia/Khandyga': 'Asia / Khandyga',
  'Asia/Kolkata': 'Asia / Kolkata',
  'Asia/Krasnoyarsk': 'Asia / Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Asia / Kuala Lumpur',
  'Asia/Kuching': 'Asia / Kuching',
  'Asia/Kuwait': 'Asia / Kuwait',
  'Asia/Macao': 'Asia / Macao',
  'Asia/Macau': 'Asia / Macau',
  'Asia/Magadan': 'Asia / Magadan',
  'Asia/Makassar': 'Asia / Makassar',
  'Asia/Manila': 'Asia / Manila',
  'Asia/Muscat': 'Asia / Muscat',
  'Asia/Nicosia': 'Asia / Nicosia',
  'Asia/Novokuznetsk': 'Asia / Novokuznetsk',
  'Asia/Novosibirsk': 'Asia / Novosibirsk',
  'Asia/Omsk': 'Asia / Omsk',
  'Asia/Oral': 'Asia / Oral',
  'Asia/Phnom_Penh': 'Asia / Phnom Penh',
  'Asia/Pontianak': 'Asia / Pontianak',
  'Asia/Pyongyang': 'Asia / Pyongyang',
  'Asia/Qatar': 'Asia / Qatar',
  'Asia/Qostanay': 'Asia / Qostanay',
  'Asia/Qyzylorda': 'Asia / Qyzylorda',
  'Asia/Rangoon': 'Asia / Rangoon',
  'Asia/Riyadh': 'Asia / Riyadh',
  'Asia/Saigon': 'Asia / Saigon',
  'Asia/Sakhalin': 'Asia / Sakhalin',
  'Asia/Samarkand': 'Asia / Samarkand',
  'Asia/Seoul': 'Asia / Seoul',
  'Asia/Shanghai': 'Asia / Shanghai',
  'Asia/Singapore': 'Asia / Singapore',
  'Asia/Srednekolymsk': 'Asia / Srednekolymsk',
  'Asia/Taipei': 'Asia / Taipei',
  'Asia/Tashkent': 'Asia / Tashkent',
  'Asia/Tbilisi': 'Asia / Tbilisi',
  'Asia/Tehran': 'Asia / Tehran',
  'Asia/Tel_Aviv': 'Asia / Tel Aviv',
  'Asia/Thimbu': 'Asia / Thimbu',
  'Asia/Thimphu': 'Asia / Thimphu',
  'Asia/Tokyo': 'Asia / Tokyo',
  'Asia/Tomsk': 'Asia / Tomsk',
  'Asia/Ujung_Pandang': 'Asia / Ujung Pandang',
  'Asia/Ulaanbaatar': 'Asia / Ulaanbaatar',
  'Asia/Ulan_Bator': 'Asia / Ulan Bator',
  'Asia/Urumqi': 'Asia / Urumqi',
  'Asia/Ust-Nera': 'Asia / Ust-Nera',
  'Asia/Vientiane': 'Asia / Vientiane',
  'Asia/Vladivostok': 'Asia / Vladivostok',
  'Asia/Yakutsk': 'Asia / Yakutsk',
  'Asia/Yangon': 'Asia / Yangon',
  'Asia/Yekaterinburg': 'Asia / Yekaterinburg',
  'Asia/Yerevan': 'Asia / Yerevan',
  'Atlantic/Azores': 'Atlantic / Azores',
  'Atlantic/Bermuda': 'Atlantic / Bermuda',
  'Atlantic/Canary': 'Atlantic / Canary',
  'Atlantic/Cape_Verde': 'Atlantic / Cape Verde',
  'Atlantic/Faeroe': 'Atlantic / Faeroe',
  'Atlantic/Faroe': 'Atlantic / Faroe',
  'Atlantic/Jan_Mayen': 'Atlantic / Jan Mayen',
  'Atlantic/Madeira': 'Atlantic / Madeira',
  'Atlantic/Reykjavik': 'Atlantic / Reykjavik',
  'Atlantic/South_Georgia': 'Atlantic / South Georgia',
  'Atlantic/St_Helena': 'Atlantic / St Helena',
  'Atlantic/Stanley': 'Atlantic / Stanley',
  'Australia/ACT': 'Australia / ACT',
  'Australia/Adelaide': 'Australia / Adelaide',
  'Australia/Brisbane': 'Australia / Brisbane',
  'Australia/Broken_Hill': 'Australia / Broken Hill',
  'Australia/Canberra': 'Australia / Canberra',
  'Australia/Currie': 'Australia / Currie',
  'Australia/Darwin': 'Australia / Darwin',
  'Australia/Eucla': 'Australia / Eucla',
  'Australia/Hobart': 'Australia / Hobart',
  'Australia/LHI': 'Australia / LHI',
  'Australia/Lindeman': 'Australia / Lindeman',
  'Australia/Lord_Howe': 'Australia / Lord Howe',
  'Australia/Melbourne': 'Australia / Melbourne',
  'Australia/NSW': 'Australia / NSW',
  'Australia/North': 'Australia / North',
  'Australia/Perth': 'Australia / Perth',
  'Australia/Queensland': 'Australia / Queensland',
  'Australia/South': 'Australia / South',
  'Australia/Sydney': 'Australia / Sydney',
  'Australia/Tasmania': 'Australia / Tasmania',
  'Australia/Victoria': 'Australia / Victoria',
  'Australia/West': 'Australia / West',
  'Australia/Yancowinna': 'Australia / Yancowinna',
  'Brazil/Acre': 'Brazil / Acre',
  'Brazil/DeNoronha': 'Brazil / DeNoronha',
  'Brazil/East': 'Brazil / East',
  'Brazil/West': 'Brazil / West',
  'CET': 'CET',
  'CST6CDT': 'CST6CDT',
  'Canada/Atlantic': 'Canada / Atlantic',
  'Canada/Central': 'Canada / Central',
  'Canada/Eastern': 'Canada / Eastern',
  'Canada/Mountain': 'Canada / Mountain',
  'Canada/Newfoundland': 'Canada / Newfoundland',
  'Canada/Pacific': 'Canada / Pacific',
  'Canada/Saskatchewan': 'Canada / Saskatchewan',
  'Canada/Yukon': 'Canada / Yukon',
  'Chile/Continental': 'Chile / Continental',
  'Chile/EasterIsland': 'Chile / EasterIsland',
  'Cuba': 'Cuba',
  'EET': 'EET',
  'EST': 'EST',
  'EST5EDT': 'EST5EDT',
  'Egypt': 'Egypt',
  'Eire': 'Eire',
  'Etc/GMT': 'Etc / GMT',
  'Etc/GMT+0': 'Etc / GMT+0',
  'Etc/GMT+1': 'Etc / GMT+1',
  'Etc/GMT+10': 'Etc / GMT+10',
  'Etc/GMT+11': 'Etc / GMT+11',
  'Etc/GMT+12': 'Etc / GMT+12',
  'Etc/GMT+2': 'Etc / GMT+2',
  'Etc/GMT+3': 'Etc / GMT+3',
  'Etc/GMT+4': 'Etc / GMT+4',
  'Etc/GMT+5': 'Etc / GMT+5',
  'Etc/GMT+6': 'Etc / GMT+6',
  'Etc/GMT+7': 'Etc / GMT+7',
  'Etc/GMT+8': 'Etc / GMT+8',
  'Etc/GMT+9': 'Etc / GMT+9',
  'Etc/GMT-0': 'Etc / GMT-0',
  'Etc/GMT-1': 'Etc / GMT-1',
  'Etc/GMT-10': 'Etc / GMT-10',
  'Etc/GMT-11': 'Etc / GMT-11',
  'Etc/GMT-12': 'Etc / GMT-12',
  'Etc/GMT-13': 'Etc / GMT-13',
  'Etc/GMT-14': 'Etc / GMT-14',
  'Etc/GMT-2': 'Etc / GMT-2',
  'Etc/GMT-3': 'Etc / GMT-3',
  'Etc/GMT-4': 'Etc / GMT-4',
  'Etc/GMT-5': 'Etc / GMT-5',
  'Etc/GMT-6': 'Etc / GMT-6',
  'Etc/GMT-7': 'Etc / GMT-7',
  'Etc/GMT-8': 'Etc / GMT-8',
  'Etc/GMT-9': 'Etc / GMT-9',
  'Etc/GMT0': 'Etc / GMT0',
  'Etc/Greenwich': 'Etc / Greenwich',
  'Etc/UCT': 'Etc / UCT',
  'Etc/UTC': 'Etc / UTC',
  'Etc/Universal': 'Etc / Universal',
  'Etc/Zulu': 'Etc / Zulu',
  'Europe/Amsterdam': 'Europe / Amsterdam',
  'Europe/Andorra': 'Europe / Andorra',
  'Europe/Astrakhan': 'Europe / Astrakhan',
  'Europe/Athens': 'Europe / Athens',
  'Europe/Belfast': 'Europe / Belfast',
  'Europe/Belgrade': 'Europe / Belgrade',
  'Europe/Berlin': 'Europe / Berlin',
  'Europe/Bratislava': 'Europe / Bratislava',
  'Europe/Brussels': 'Europe / Brussels',
  'Europe/Bucharest': 'Europe / Bucharest',
  'Europe/Budapest': 'Europe / Budapest',
  'Europe/Busingen': 'Europe / Busingen',
  'Europe/Chisinau': 'Europe / Chisinau',
  'Europe/Copenhagen': 'Europe / Copenhagen',
  'Europe/Dublin': 'Europe / Dublin',
  'Europe/Gibraltar': 'Europe / Gibraltar',
  'Europe/Guernsey': 'Europe / Guernsey',
  'Europe/Helsinki': 'Europe / Helsinki',
  'Europe/Isle_of_Man': 'Europe / Isle of Man',
  'Europe/Istanbul': 'Europe / Istanbul',
  'Europe/Jersey': 'Europe / Jersey',
  'Europe/Kaliningrad': 'Europe / Kaliningrad',
  'Europe/Kiev': 'Europe / Kiev',
  'Europe/Kirov': 'Europe / Kirov',
  'Europe/Kyiv': 'Europe / Kyiv',
  'Europe/Lisbon': 'Europe / Lisbon',
  'Europe/Ljubljana': 'Europe / Ljubljana',
  'Europe/London': 'Europe / London',
  'Europe/Luxembourg': 'Europe / Luxembourg',
  'Europe/Madrid': 'Europe / Madrid',
  'Europe/Malta': 'Europe / Malta',
  'Europe/Mariehamn': 'Europe / Mariehamn',
  'Europe/Minsk': 'Europe / Minsk',
  'Europe/Monaco': 'Europe / Monaco',
  'Europe/Moscow': 'Europe / Moscow',
  'Europe/Nicosia': 'Europe / Nicosia',
  'Europe/Oslo': 'Europe / Oslo',
  'Europe/Paris': 'Europe / Paris',
  'Europe/Podgorica': 'Europe / Podgorica',
  'Europe/Prague': 'Europe / Prague',
  'Europe/Riga': 'Europe / Riga',
  'Europe/Rome': 'Europe / Rome',
  'Europe/Samara': 'Europe / Samara',
  'Europe/San_Marino': 'Europe / San Marino',
  'Europe/Sarajevo': 'Europe / Sarajevo',
  'Europe/Saratov': 'Europe / Saratov',
  'Europe/Simferopol': 'Europe / Simferopol',
  'Europe/Skopje': 'Europe / Skopje',
  'Europe/Sofia': 'Europe / Sofia',
  'Europe/Stockholm': 'Europe / Stockholm',
  'Europe/Tallinn': 'Europe / Tallinn',
  'Europe/Tirane': 'Europe / Tirane',
  'Europe/Tiraspol': 'Europe / Tiraspol',
  'Europe/Ulyanovsk': 'Europe / Ulyanovsk',
  'Europe/Uzhgorod': 'Europe / Uzhgorod',
  'Europe/Vaduz': 'Europe / Vaduz',
  'Europe/Vatican': 'Europe / Vatican',
  'Europe/Vienna': 'Europe / Vienna',
  'Europe/Vilnius': 'Europe / Vilnius',
  'Europe/Volgograd': 'Europe / Volgograd',
  'Europe/Warsaw': 'Europe / Warsaw',
  'Europe/Zagreb': 'Europe / Zagreb',
  'Europe/Zaporozhye': 'Europe / Zaporozhye',
  'Europe/Zurich': 'Europe / Zurich',
  'GB': 'GB',
  'GB-Eire': 'GB-Eire',
  'GMT': 'GMT',
  'GMT+0': 'GMT+0',
  'GMT-0': 'GMT-0',
  'GMT0': 'GMT0',
  'Greenwich': 'Greenwich',
  'HST': 'HST',
  'Hongkong': 'Hongkong',
  'Iceland': 'Iceland',
  'Indian/Antananarivo': 'Indian / Antananarivo',
  'Indian/Chagos': 'Indian / Chagos',
  'Indian/Christmas': 'Indian / Christmas',
  'Indian/Cocos': 'Indian / Cocos',
  'Indian/Comoro': 'Indian / Comoro',
  'Indian/Kerguelen': 'Indian / Kerguelen',
  'Indian/Mahe': 'Indian / Mahe',
  'Indian/Maldives': 'Indian / Maldives',
  'Indian/Mauritius': 'Indian / Mauritius',
  'Indian/Mayotte': 'Indian / Mayotte',
  'Indian/Reunion': 'Indian / Reunion',
  'Iran': 'Iran',
  'Israel': 'Israel',
  'Jamaica': 'Jamaica',
  'Japan': 'Japan',
  'Kwajalein': 'Kwajalein',
  'Libya': 'Libya',
  'MET': 'MET',
  'MST': 'MST',
  'MST7MDT': 'MST7MDT',
  'Mexico/BajaNorte': 'Mexico / BajaNorte',
  'Mexico/BajaSur': 'Mexico / BajaSur',
  'Mexico/General': 'Mexico / General',
  'NZ': 'NZ',
  'NZ-CHAT': 'NZ-CHAT',
  'Navajo': 'Navajo',
  'PRC': 'PRC',
  'PST8PDT': 'PST8PDT',
  'Pacific/Apia': 'Pacific / Apia',
  'Pacific/Auckland': 'Pacific / Auckland',
  'Pacific/Bougainville': 'Pacific / Bougainville',
  'Pacific/Chatham': 'Pacific / Chatham',
  'Pacific/Chuuk': 'Pacific / Chuuk',
  'Pacific/Easter': 'Pacific / Easter',
  'Pacific/Efate': 'Pacific / Efate',
  'Pacific/Enderbury': 'Pacific / Enderbury',
  'Pacific/Fakaofo': 'Pacific / Fakaofo',
  'Pacific/Fiji': 'Pacific / Fiji',
  'Pacific/Funafuti': 'Pacific / Funafuti',
  'Pacific/Galapagos': 'Pacific / Galapagos',
  'Pacific/Gambier': 'Pacific / Gambier',
  'Pacific/Guadalcanal': 'Pacific / Guadalcanal',
  'Pacific/Guam': 'Pacific / Guam',
  'Pacific/Honolulu': 'Pacific / Honolulu',
  'Pacific/Johnston': 'Pacific / Johnston',
  'Pacific/Kanton': 'Pacific / Kanton',
  'Pacific/Kiritimati': 'Pacific / Kiritimati',
  'Pacific/Kosrae': 'Pacific / Kosrae',
  'Pacific/Kwajalein': 'Pacific / Kwajalein',
  'Pacific/Majuro': 'Pacific / Majuro',
  'Pacific/Marquesas': 'Pacific / Marquesas',
  'Pacific/Midway': 'Pacific / Midway',
  'Pacific/Nauru': 'Pacific / Nauru',
  'Pacific/Niue': 'Pacific / Niue',
  'Pacific/Norfolk': 'Pacific / Norfolk',
  'Pacific/Noumea': 'Pacific / Noumea',
  'Pacific/Pago_Pago': 'Pacific / Pago Pago',
  'Pacific/Palau': 'Pacific / Palau',
  'Pacific/Pitcairn': 'Pacific / Pitcairn',
  'Pacific/Pohnpei': 'Pacific / Pohnpei',
  'Pacific/Ponape': 'Pacific / Ponape',
  'Pacific/Port_Moresby': 'Pacific / Port Moresby',
  'Pacific/Rarotonga': 'Pacific / Rarotonga',
  'Pacific/Saipan': 'Pacific / Saipan',
  'Pacific/Samoa': 'Pacific / Samoa',
  'Pacific/Tahiti': 'Pacific / Tahiti',
  'Pacific/Tarawa': 'Pacific / Tarawa',
  'Pacific/Tongatapu': 'Pacific / Tongatapu',
  'Pacific/Truk': 'Pacific / Truk',
  'Pacific/Wake': 'Pacific / Wake',
  'Pacific/Wallis': 'Pacific / Wallis',
  'Pacific/Yap': 'Pacific / Yap',
  'Poland': 'Poland',
  'Portugal': 'Portugal',
  'ROC': 'ROC',
  'ROK': 'ROK',
  'Singapore': 'Singapore',
  'Turkey': 'Turkey',
  'UCT': 'UCT',
  'US/Alaska': 'US / Alaska',
  'US/Aleutian': 'US / Aleutian',
  'US/Arizona': 'US / Arizona',
  'US/Central': 'US / Central',
  'US/East-Indiana': 'US / East-Indiana',
  'US/Eastern': 'US / Eastern',
  'US/Hawaii': 'US / Hawaii',
  'US/Indiana-Starke': 'US / Indiana-Starke',
  'US/Michigan': 'US / Michigan',
  'US/Mountain': 'US / Mountain',
  'US/Pacific': 'US / Pacific',
  'US/Samoa': 'US / Samoa',
  'UTC': 'UTC',
  'Universal': 'Universal',
  'W-SU': 'W-SU',
  'WET': 'WET',
  'Zulu': 'Zulu',
};

export const EVENT_TITLE_TOKENS = [
  {
    token: '$invitee',
    value: '${invitee}',
    description: 'The name of the primary invitee.',
  },
  {
    token: '$invitee_email',
    value: '${invitee_email}',
    description: 'The email of the primary invitee.',
  },
  // {
  //   token: '$all_invitee_emails',
  //   value: '${all_invitee_emails}',
  //   description: 'The email of primary invitee and any additional guest emails.',
  // },
  {
    token: '$duration',
    value: '${duration}',
    description: 'The length in time of the event.',
  },
];

export const DEFAULT_OPEN_HOURS: OpenHours[] = [
  {
    days: [1, 2, 3, 4, 5],
    ex_dates: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    start: '09:00',
    end: '17:00',
  },
];

export const PROVIDER_CONFERENCE_MAP = {
  google: 'Google Meet',
  zoom: 'Zoom Meeting',
  microsoft: 'Microsoft Teams',
};

export const CONFERENCE_PROVIDER_MAP = {
  'Google Meet': 'google',
  'Zoom Meeting': 'zoom',
  'Microsoft Teams': 'microsoft',
};

export const NON_PROVIDER_CONFERENCE_OPTIONS = ['zoom'];
export const DEFAULT_FORM_FIELD_TYPES = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone Number',
    value: 'phone_number',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Short text',
    value: 'text',
  },
  {
    label: 'Long text',
    value: 'multi_line_text',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Radio Button',
    value: 'radio_button',
  },
  // {
  //   label: 'Date',
  //   value: 'date',
  // },
  // {
  //   label: 'Multi Select',
  //   value: 'multi_select',
  // },
  // {
  //   label: 'Number',
  //   value: 'number',
  // },
];
export const FIELD_TYPES: any = [
  {
    type: 'email',
    typeLabel: 'Email',
    label: 'Additional email',
    key: 'additional_email',
    isOpen: false,
    required: false,
  },
  {
    type: 'phone_number',
    typeLabel: 'Phone number',
    label: 'Phone Number Label',
    key: 'phone_number',
    isOpen: false,
    required: false,
  },
  {
    type: 'dropdown',
    typeLabel: 'Dropdown',
    label: 'Dropdown Label',
    key: 'dropdown',
    isOpen: false,
    required: false,
    options: ['', ''],
  },
  {
    type: 'text',
    typeLabel: 'Short text',
    label: 'Short Text Label',
    key: 'short_text',
    isOpen: false,
    required: false,
  },
  {
    type: 'multi_line_text',
    typeLabel: 'Long text',
    label: 'Long text Label',
    key: 'long_text',
    isOpen: false,
    required: false,
  },
  {
    type: 'checkbox',
    typeLabel: 'Checkbox',
    label: 'Checkbox Label',
    key: 'checkbox',
    isOpen: false,
    required: false,
  },
  {
    type: 'radio_button',
    typeLabel: 'Radio Button',
    label: 'Radio Button Label',
    key: 'radio_button',
    isOpen: false,
    required: false,
    options: ['', ''],
  },
  // {
  //   type: 'date',
  //   typeLabel: 'Date',
  //   label: 'Date of Birth',
  //   key: 'date_of_birth',
  //   isOpen: false,
  //   required: false,
  // },
  // {
  //   type: 'multi_select',
  //   typeLabel: 'Multi Select',
  //   label: 'Multi Select Label',
  //   key: 'multi_select',
  //   isOpen: false,
  //   required: false,
  //   options: ['', ''],
  // },
  // {
  //   type: 'number',
  //   typeLabel: 'Number',
  //   label: 'Number Label',
  //   key: 'number',
  //   isOpen: false,
  //   required: false,
  // },
];
export const PROVIDER_CONFERENCE_OPTIONS = Object.keys(PROVIDER_CONFERENCE_MAP).map(provider => provider);

export const REMINDER_MINUTE_OPTIONS = [
  { value: 1, label: '1' },
  ...Array.from({ length: 90 / 5 + 1 }, (_, i) => {
    const value = i * 5;
    return { value, label: value.toString() };
  }),
].sort((a, b) => a.value - b.value);

export const REMINDER_HOUR_OPTIONS = Array.from({ length: 23 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));

export const REMINDER_DAY_OPTIONS = Array.from({ length: 7 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));
