import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'chevron-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ChevronIcon {
  @Prop() width: string = '24';
  @Prop() height: string = '24';

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.width} height={this.height} viewBox="0 0 24 24" fill="none">
        <path fill="currentColor" d="M15.53 4.22a.75.75 0 0 1 0 1.06L8.81 12l6.72 6.72a.75.75 0 1 1-1.06 1.06l-7.25-7.25a.75.75 0 0 1 0-1.06l7.25-7.25a.75.75 0 0 1 1.06 0Z" />
      </svg>
    );
  }
}
